/*
  Authors : raysoft
  Website : https://raysoft.in/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://raysoft.in/license
  Copyright and Good Faith Purchasers © 2020-present raysoft.
*/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://raysoft.in/localstores-api/index.php/',
  mediaURL: 'https://raysoft.in/localstores-api/uploads/',
  onesignal: {
    appId: 'af1a1433-dce0-4f70-8b81-d59f0b4d0c00',
    googleProjectNumber: '1060858517034',
    restKey: 'ZTliNDAzZDItZDVkMi00NDk2LTk3NmQtM2U0ODMyZmVmMjk1'
  },
  general: {
    symbol: '₹',
    code: 'INR'
  },
  authToken: '123456789'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
